@use '@angular/material' as mat;
// angular notifier

@import "node_modules/angular-notifier/styles";

@include mat.core();


$my-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);
$my-danger: mat.define-palette(mat.$red-palette, A200, A100, A400);

$accent: #2196F3;
$ng-success: #00C853;
$ng-error: #dc3545;
$ng-secondary-disabled: #9e9e9e;
$ng-secondary: #616161;


:root {
  --primary-color: #FFFFFF;
  --primary-color-disabled: rgb(255, 255, 255, 0.5);
  --primary-button-color: #2196F3;
  --primary-button-color-transparency-054: rgb(33, 150, 243, 0.56);
  --primary-button-color-transparency-025: rgb(33, 150, 243, 0.25);
  --background-color: white;
}

$my-primary: (
  50: var(--primary-color),
  100: var(--primary-color),
  200: var(--primary-color),
  300: var(--primary-color),
  400: var(--primary-color),
  500: var(--primary-color),
  600: var(--primary-color),
  700: var(--primary-color),
  800: var(--primary-color),
  900: var(--primary-color),
  A100:var(--primary-color),
  A200:var(--primary-color),
  A400: var(--primary-color),
  A700:var(--primary-color),
  contrast: (
    50: var(--primary-color),
    100:var(--primary-color),
    200:var(--primary-color),
    300: var(--primary-color),
    400: var(--primary-color),
    500: var(--primary-color),
    600: var(--primary-color),
    700: var(--primary-color),
    800:var(--primary-color),
    900:var(--primary-color),
    A100: var(--primary-color),
    A200:var(--primary-color),
    A400:var(--primary-color),
    A700:var(--primary-color),
  )
);

$my-secondary: (
  50: var(--primary-button-color),
  100: var(--primary-button-color),
  200: var(--primary-button-color),
  300: var(--primary-button-color),
  400: var(--primary-button-color),
  500: var(--primary-button-color),
  600: var(--primary-button-color),
  700: var(--primary-button-color),
  800: var(--primary-button-color),
  900: var(--primary-button-color),
  A100:var(--primary-button-color),
  A200:var(--primary-button-color),
  A400: var(--primary-button-color),
  A700:var(--primary-button-color),
  contrast: (
    50: var(--primary-button-color),
    100:var(--primary-button-color),
    200:var(--primary-button-color),
    300: var(--primary-button-color),
    400: var(--primary-button-color),
    500: var(--primary-button-color),
    600: var(--primary-button-color),
    700: var(--primary-button-color),
    800:var(--primary-button-color),
    900:var(--primary-button-color),
    A100: var(--primary-button-color),
    A200:var(--primary-button-color),
    A400:var(--primary-button-color),
    A700:var(--primary-button-color),
  )
);

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
    secondary: $my-secondary,
    warn: $my-danger
  ),
  density: 0,
));

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($my-theme);

// Emit styles for MatButton based on `$my-theme`. Because the configuration
// passed to `define-light-theme` omits typography, `button-theme` will not
// emit any typography styles.
@include mat.button-theme($my-theme);

// Include the theme mixins for other components you use here.


@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./assets/fonts/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
  U+FE2E-FE2F;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/icons/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}


@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 100 700;
  font-display: swap;
  src: url(./assets/icons/MaterialSymbolsOutlined_Dec2024.woff2) format('woff2');
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.icon-filter {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}


body {
  margin: 0;
  padding: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-start {
  justify-content: flex-start;
}

.flex-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.flex-center {
  justify-content: center;
}

.flex-around {
  justify-content: space-around;
}

.flex-evenly {
  justify-content: space-evenly;
}

.flex-top {
  align-items: flex-start;
}

.flex-middle {
  align-items: center;
}

.flex-bottom {
  align-items: flex-end;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.text-center {
  text-align: center;
}
